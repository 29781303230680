import { template as template_a17e9e2f849645fb8fa6bcc9d90f2458 } from "@ember/template-compiler";
const FKLabel = template_a17e9e2f849645fb8fa6bcc9d90f2458(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
