import { template as template_6ce5e66d714d4b769771a211988799bb } from "@ember/template-compiler";
const FKControlMenuContainer = template_6ce5e66d714d4b769771a211988799bb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
